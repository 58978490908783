import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-440a3b5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: "_sm_icon",
      style: _normalizeStyle(_ctx.iconSize),
      id: "wechat",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showWechat && _ctx.showWechat(...args))),
      target: "_blank"
    }, null, 4),
    _createElementVNode("a", {
      class: "_sm_icon",
      style: _normalizeStyle(_ctx.iconSize),
      id: "weibo",
      href: "https://weibo.com/u/2505331282",
      target: "_blank"
    }, null, 4),
    _createElementVNode("a", {
      class: "_sm_icon",
      style: _normalizeStyle(_ctx.iconSize),
      id: "toutiao",
      href: "https://www.toutiao.com/c/user/token/MS4wLjABAAAAISKFxmYVnp9JkbKrkY-4RzFFgdcq_flX1RSEUId-U_dVDUn5p3U0EkLkJmC2OUhp/?",
      target: "_blank"
    }, null, 4),
    _createElementVNode("a", {
      class: "_sm_icon",
      style: _normalizeStyle(_ctx.iconSize),
      id: "zhihu",
      href: "https://www.zhihu.com/org/hua-mei-gu-wen-24",
      target: "_blank"
    }, null, 4)
  ]))
}