
import { defineComponent, ref } from 'vue'
import {modal} from '@/modal'
import axios from 'axios'
import {backEndURL} from '@/siteConfig'

export default defineComponent({
  name: "ContactForm",
  setup(){

      const showExpansion = ref(false)

      interface ContactForm {
        name:string,
        email:string,
        phone:string,
        title:string,
        city:string,
        company:string,
        message:string,
      }
    const form = ref({
        name:'',
        email:'',
        phone:'',
        title:'',
        city:'',
        company:'',
        message:'',
    })
    const showAlert = ref({
        name:'',
        email:'',
        phone:'',
        title:'',
        city:'',
        company:'',
        message:'',
    })

    const sendMessage = (form:ContactForm) => {
        return new Promise((resolve, reject) =>{
            axios.post(`${backEndURL}/public/contact-form`, {form}).then(res =>{
                resolve(res)
            }).catch(err=>{
                reject(err)
            })
        })
    }

    const onSubmit = () => {
        if(!(form.value.email || form.value.phone )){
            modal.alert('请填写邮箱或电话！')
            return
        }
        sendMessage(form.value).then((res:any)=>{
            if(res.data.status == 200){
                modal.alert('感谢留言，我们会尽快联系您！')
            }else{
                modal.alert('发送失败，请稍后再试！')
            }
        }).catch(err=>{
            modal.alert('发送失败，请稍后再试！')
        })
    }
    return { 
        form,
        showAlert,
        showExpansion,
        onSubmit
    }
  },
})
