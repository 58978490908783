
/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref, nextTick } from "vue";
import scroll from '@/scroll'
import ContactForm from '@/components/ContactForm.vue'
import pageData from '@/pageData/contact'
import SocialMedia from '@/components/SocialMediaContact.vue'
import {modal} from '@/modal'

export default defineComponent({
    props: {
        headImage: {
        default: "",
        },
        currTab:{default: ""}
    },
    components:{
        ContactForm,
        SocialMedia
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        const showWechat = ()=> {
            modal.display('扫描二维码关注最新动态', 'https://oss.huamei2001.com/hm2022/social_media/wxaccqrcode.jpg')
        }
        return {
            showWechat,
            pageData,
            scroll,
            idHash
        }
    }
});
