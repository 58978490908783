
import { useRouter } from 'vue-router';
import store from '@/store'
import {modal} from '@/modal'
import { defineComponent, ref, computed, watch, PropType } from 'vue'
import {pages, PageItem}  from '@/siteConfig'

export default defineComponent({
  name: "SocialMedia",
  props:{
      iconSize: {
          default:'width:50px;height:50px;'
      }
  },
  components:{
  },
  setup(){

      const showWechat = ()=> {
          modal.display('扫描二维码关注最新动态', 'images/wxaccqrcode.jpg')
      }
    return { 
        showWechat,
        modal
    }
  },
})
